@media (max-width: 600px) {
 .main {
  min-height: 700px;
  border-radius: 0 0 20px 20px;
 }
 .section-title {
  font-size: 2.3rem;
  margin-bottom: 6.4rem;
  font-weight: 700;
  margin-top: 12rem;
 }

 .title-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2rem;
 }

 .contact-info {
  /* display: none; */
  flex-wrap: wrap;
 }

 .showcase-content {
  text-align: center;
  color: #fff;
  font-size: 16px;
  max-width: 350px;
  margin: auto;
 }
 .showcase-content h1 {
  margin-bottom: 10px;
  font-size: 3rem;
  line-height: 1.6;
  max-width: 98%;
  position: relative;
  top: -35px;
 }
 .showcase-content a {
  font-size: 1.5rem;
  padding: 1.5rem 2rem;
 }

 .showcase-search {
  width: 90%;
  margin: auto;
  display: flex;
  align-items: center;
  background-color: transparent;
  justify-content: space-between;
  padding: 1rem 2rem;
  padding-right: 1rem;
  border-radius: 12px;
  margin: 60px 0;
 }
 .swiper-wrapper {
  padding: 0 0 3.75rem;
 }

 .filters {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  justify-content: space-between;
  align-items: flex-start;
  flex-shrink: 10px;
  gap: 2rem;
  font-size: 1rem;
 }

 .destinations {
  margin-top: 6rem;
  padding-left: 1rem;
 }

 .destination-card img {
  object-fit: cover;
  border-radius: 10px;
  width: 170px;
 }
 .filter {
  justify-content: flex-start;
  align-items: flex-end;
  /* margin: auto; */
  display: flex;
  line-height: 1.6;
  gap: 1.5rem;
  border-right: 1px solid rgba(194, 193, 193, 0.1);
  padding-right: 0rem;
 }

 .hotel-cards {
  display: grid;
  grid-template-columns: 1fr;
  justify-content: space-between;
  align-items: flex-start;
  gap: 10px;
  margin: auto;
  /* padding: 1rem; */
 }

 .hotel-cards > img {
  border-radius: 12px;
  width: 100%;
 }

 .ratings {
  position: static;
  bottom: 1px;
 }

 .title-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
 }
}
