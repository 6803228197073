/* variables */

:root {
 /* color palate */
 --primary-color: #7b61ff;
 --secondary-color: #f6f4ff;
 --footer-color: #f9f9f9;

 /* font size & weight */
 --title-font-size: 3.2rem;
 --small-font-size: 1.3rem;
 --h2-font-weight: 600;

 /* card-gap */
 --card--gap: 3.2rem;

 --conatiner--size: 73%;
}

/* headEr */
.logo img {
 width: 150px;
 height: 75px;
}

.header .nav-container {
 color: #fff;
 max-width: var(--conatiner--size);
 margin: auto;
 display: flex;
 justify-content: space-between;
 align-items: center;
 padding-top: 4rem;
 margin-bottom: 160px;
}
@media (max-width: 992px) {
 .header .nav-container {
  max-width: 90%;
 }
}
@media (max-width: 800px) {
 .header .nav-container {
  max-width: 95%;
 }
}

/* NAVIGATIONS */
.navlists {
 display: flex;
 justify-content: space-between;
 align-items: center;
 gap: 2.4rem;
 font-size: 1.4rem;
 font-weight: 300;
}
.navlists a {
 color: var(--light);
}
.navlists a:hover {
 color: var(--yellow);
}
.navlink.active {
 color: var(--yellow);
}
/* toggle icons for mobile navigation */
.toggleOn {
 display: none;
}

.toggleClose {
 display: none;
}

@media (max-width: 768px) {
 /* NAVBAR */

 .navbar {
  /* order: 3; */
  position: fixed;
  left: 0;
  right: 0;
  bottom: -80px;
  color: white;
  background-color: #7b61ff;
  z-index: 30;
  background: #000000;
  padding: 3rem;
  border-radius: 60px 60px 0px 0px;
  transform: translateY(100px);
 }

 .navlists {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  justify-content: center;
  align-items: center;
  text-align: center;
  line-height: 4.6;
  /* font-size: 1rem; */
 }
 .header .nav-container {
  color: #fff;
  max-width: var(--conatiner--size);
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 5.5rem;
  margin-bottom: 160px;
 }

 .toggleOn {
  position: fixed;
  top: 10px;
  left: 0;
  right: 0;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
  color: rgb(243, 243, 243);
  z-index: 50;
  cursor: pointer;
 }
 .toggleClose {
  position: fixed;
  top: 10px;
  left: 0;
  right: 0;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
  color: rgb(243, 243, 243);
  z-index: 50;
  cursor: pointer;
 }

 .navbar.navlistOn {
  transform: translateY(0);
 }

 .toggleClose {
  display: none;
 }

 .toggleCloseOn {
  display: inline-flex;
 }

 .toggleOnClose {
  display: none;
 }
}

/* TOP CONTACT BAR */
.contact-info {
 position: absolute;
 top: 0;
 left: 0;
 width: 100%;
 min-height: 30px;
 background-color: black;
 display: flex;
 justify-content: space-evenly;
}
.contact-info-item {
 display: flex;
 align-items: center;
 color: var(--yellow);
 opacity: 0.8;
 cursor: pointer;
}
.contact-info-item:hover {
 opacity: 1;
}
.contact-info-item a {
 font-size: 1.2rem;
 font-weight: 500;
 margin-right: 1rem;
 color: currentColor;
 display: flex;
 align-items: center;
}
.contact-info-item a:hover .contact-icon {
 opacity: 1;
}
.contact-icon {
 margin-right: 6px;
 height: 18px;
 width: 18px;
}

/* USER/LOGIN */

.user-profile {
 display: flex;
 justify-content: flex-end;
 align-items: center;
 gap: 1rem;
}

.user-profile h2 {
 font-weight: 600;
 display: flex;
 flex-direction: column;
 justify-content: center;
 line-height: 2.1rem;
 align-items: flex-end;
 font-size: 1.4rem;
}
.hello {
 opacity: 0.7;
}

.profile-pic {
 width: 48px;
 height: 48px;
}
.profile-pic img {
 border-radius: 50%;
 width: 100%;
}
.login-button button {
 background: linear-gradient(90deg, var(--red) 100%, var(--blue) 100%);
 padding: 1.5rem 2.5rem;
 border-radius: 1.2rem;
 font-size: 1.4rem;
 font-weight: 500;
 color: #fff;
 cursor: pointer;
 border: none;
}
/* animate linear gradient */
.login-button button:hover {
 background-size: 200%;
 animation: animate 1s linear infinite;
}
/* variable Class */
.section-title {
 font-size: var(--title-font-size);
 margin: 12rem 0 6.4rem 0px;
 font-weight: 700;
}
.destinations .section-title {
 text-align: center;
}

.container {
 max-width: 1378px;
 margin: auto;
}

.section-button {
 font-size: 1.6rem;
 margin-top: 4.5rem;
 display: flex;
 flex-direction: row;
 justify-content: center;
 align-items: center;
 padding: 10px;
 gap: 4px;
 min-width: 105px;
 height: 44px;
 background: #f6f4ff;
 color: #7b61ff;
 border-radius: 12px;
 cursor: pointer;
 /* margin-left: -6rem; */
}

/* main */

.main {
 position: relative;
 min-height: 800px;
 border-radius: 0 0 50px 50px;
}
/* add overlay to main */
.main::before {
 content: "";
 position: absolute;
 top: 0;
 left: 0;
 width: 100%;
 height: 100%;
 background: linear-gradient(to bottom, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0.1) 100%);
 border-radius: 0 0 50px 50px;
 z-index: 2;
}
/* showcase CONTENT */
.showcase-content {
 text-align: center;
 color: #fff;
 font-size: 25px;
 margin-bottom: 350px;
}
@media screen and (max-width: 1200px) {
 .showcase-content {
  margin-bottom: 300px;
 }
}
@media screen and (max-width: 820px) {
 .showcase-content {
  margin-bottom: 250px;
 }
}

@media screen and (max-width: 720px) {
 .showcase-content {
  margin-bottom: 170px;
 }
}

@media screen and (max-width: 680px) {
 .showcase-content {
  margin-bottom: 130px;
 }
}

.showcase-content h1 {
 margin-bottom: 42px;
 line-height: 1.2;
 max-width: calc(var(--conatiner--size) - 100px);
 margin: 0 auto;
}
.showcase-content a {
 text-align: center;
 font-size: 1.8rem;
 margin-top: 32px;
 display: inline-flex;
 justify-content: space-between;
 align-items: center;
 gap: 1rem;
 /* background: var(--purple); */
 /* background-image: linear-gradient(to right, var(--red) 0%, var(--blue) 51%, var(--red) 100%); */
 background-color: #000;
 background-size: 200% auto;
 transition: 0.5s;
 padding: 1.8rem 2.4rem;
 border-radius: 1.2rem;
}
.showcase-content a:hover {
 color: var(--yellow);
}
.showcase-content a:hover svg {
 transform: rotate(360deg);
}
.btn-grad:hover {
 background-position: right center; /* change the direction of the change here */
 color: #fff;
 text-decoration: none;
}

/* destination */
.swiper-wrapper {
 /* padding: 0 2rem; */
 padding-bottom: 4.5rem;
}
.swiper-pagination-bullet-active-main {
 background: var(--purple) !important;
}
@media screen and (max-width: 680px) {
 .destinations h2.section-title {
  margin-top: 17rem;
  margin-bottom: 4rem;
 }
}

.destination-card h5 {
 font-size: 1.6rem;
 font-weight: 500;
 color: var(--purple);
}
.destination-card h6 {
 font-weight: 400;
 font-size: 1.3rem;
 line-height: 19.5px;
 opacity: 0.5;
}
.destination-card img {
 object-fit: cover;
 border-radius: 10px;
}

@media screen and (max-width: 1250px) {
 .destinations .container {
  max-width: 95%;
  margin: auto;
 }
}

/* HOTELS */
.hotel-restaurants {
 transition: 3s;
}

.title-container {
 display: flex;
 align-items: center;
 justify-content: space-between;
 max-height: 100px;
 max-width: 95%;
 margin: 0 auto 20px;
}
.empty-slider {
 display: flex;
 align-items: center;
 justify-content: center;
 flex-direction: column;
 height: 100%;
 width: 100%;
}
.hotel-cards {
 display: grid;
 grid-template-columns: 1fr 1fr 1fr 1fr;
 justify-content: space-between;
 align-items: center;
 gap: 3rem;
 max-width: 90%;
 margin: 0 auto;
 transition: 0.3s;
}

@media screen and (max-width: 1250px) {
 .hotel-cards {
  grid-template-columns: 1fr 1fr 1fr;
 }
}
@media screen and (max-width: 970px) {
 .hotel-cards {
  grid-template-columns: 1fr 1fr;
 }
}
@media screen and (max-width: 600px) {
 .hotel-cards {
  grid-template-columns: 1fr;
 }
}

.hotel-card {
 position: relative;
 border-radius: 12px;
}
.hotel-card img {
 border-radius: 12px;
}
.hotel-card p {
 font-weight: 500;
 font-size: 1.6rem;
 margin-block-start: 0;
 padding: 0;
 text-align-last: left;
 overflow: hidden;
 white-space: nowrap;
 text-overflow: ellipsis;
}
.hotel-card p a {
 color: var(--purple);
}
.hotel-card .hotel-info {
 display: flex;
 justify-content: space-between;
 font-weight: 400;
 font-size: 1.3rem;
 line-height: 19.5px;
 display: flex;
 align-items: center;
 gap: 1rem;
 margin-top: 1rem;
 text-align: left;
}
.hotel-info div {
 display: flex;
 align-items: center;
}
.hotel-info div svg {
 height: 15px;
}
.price {
 /* background-color: var(--purple); */
 color: var(--purple);
 border: 2px solid var(--purple);
 padding: 2px 10px;
 font-size: 12px;
 border-radius: 5px;
}
.off {
 display: none;
}

.on {
 display: block;
}
.search-items {
 display: grid;
 grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
 grid-gap: 20px;
}
@media screen and (max-width: 1200px) {
 .search-items {
  grid-template-columns: 1fr 1fr 1fr 1fr;
 }
}
@media screen and (max-width: 990px) {
 .search-items {
  grid-template-columns: 1fr 1fr 1fr;
 }
}
@media screen and (max-width: 767px) {
 .search-items {
  grid-template-columns: 1fr 1fr;
 }
}
.search-items label {
 font-size: 1.5rem;
 color: var(--purple);
}
.css-t3ipsp-control {
 border-color: var(--purple) !important;
 box-shadow: 0 0 0 1px var(--purple) !important;
}
.search-items {
 font-size: 1.25rem !important;
}
/* tour/travel */

.packages-cards.flex {
 display: flex;
 gap: 3rem;
 max-width: 95%;
 margin: 0 auto;
}
.packages-cards.grid {
 display: grid;
 grid-template-columns: 1fr 1fr;
 justify-content: space-between;
 align-items: center;
 gap: 3rem;
 max-width: 95%;
 margin: 0 auto;
}

.packages-card {
 display: flex;
 justify-content: space-between;
 gap: 3rem;
}

.packages-card-content {
 display: flex;
 flex-direction: column;
 justify-content: space-between;
 font-size: 1.6rem;
 padding-left: 0.5rem;
}
.packages-card-content .package-name {
 margin: 0;
}

.packages-card-content .package-name a {
 color: var(--purple);
 font-size: 2rem;
 font-weight: 600;
}
.packages-card-content .package-desc {
 overflow: hidden;
 text-overflow: ellipsis;
 display: -webkit-box;
 -webkit-line-clamp: 6;
 line-clamp: 6;
 -webkit-box-orient: vertical;
 line-height: 1.75;
}
.packages-card-content-icons {
 display: flex;
 gap: 0.5rem 2rem;
 /* margin-top: 6rem; */
 flex-wrap: wrap;
}
.packages-card-content-btn a {
 font-size: 1.6rem;
 display: flex;
 flex-direction: row;
 justify-content: center;
 align-items: center;
 padding: 10px 12px 10px 15px;
 gap: 4px;
 min-width: 105px;
 height: 44px;
 background: #f6f4ff;
 color: #7b61ff;
 border-radius: 12px;
 cursor: pointer;
 max-width: 200px;
}
.packages-cards.grid .packages-card-content-icons {
 flex-wrap: wrap;
}
.packages-card-content-icons svg {
 height: 17px;
}
.packages-card-content-icons p {
 display: inline-flex;
 justify-content: space-between;
 align-items: center;
 gap: 0.6rem;
 font-weight: 600;
 font-size: 13px;
 color: #878787;
 white-space: nowrap;
}
.packages-card-contents-text {
 text-align: left;
}
.packages-card-contents-text p {
 margin-top: 2rem;
 color: #555;
 text-align: left;
}
.packages-card .tour-img-box {
 display: flex;
 justify-content: center;
}
.packages-card .tour-img-box img {
 border-radius: 12px;
 height: 380px;
 width: 320px;
 cursor: pointer;
}

@media screen and (max-width: 1420px) {
 .packages-cards.flex {
  gap: 1rem;
 }
 .packages-card {
  gap: 1rem;
 }
}

@media screen and (max-width: 1200px) {
 .flex .packages-card img.tour-img {
  height: 300px;
  width: 280px;
 }
 .grid .packages-card img.tour-img {
  width: 100%;
 }
 .packages-cards.grid .packages-card {
  flex-direction: column;
 }
 .packages-card .packages-card-content-icons {
  margin: 10px 0;
 }
}
@media screen and (max-width: 1100px) {
 .packages-card {
  display: flex;
  flex-direction: column;
 }
 .flex .packages-card img.tour-img {
  height: 380px;
  width: 320px;
 }
 .packages-card-contents-text {
  text-align: center;
 }
 .packages-card-content-icons {
  margin-top: 4rem;
 }
}

@media screen and (max-width: 730px) {
 .packages-cards.flex {
  display: flex;
  flex-direction: column;
  gap: 5rem;
 }
 .packages-cards.grid {
  display: grid;
  grid-template-columns: 1fr;
  gap: 5rem;
 }
 .packages-card {
  max-width: 90%;
  margin: 0 auto;
 }

 .packages-card-content-icons {
  margin-top: 1rem;
 }
 .packages-card .tour-img-box {
  display: block;
  width: 100%;
 }
 .packages-card span {
  width: 100%;
 }
 .packages-card img.tour-img {
  width: 100% !important;
 }
}

/* activities */
.activities-cards img {
 border-radius: 12px;
 width: 320px;
 height: 380px;
}
.activities-cards h4 {
 font-size: 1.6rem;
 text-align: center;
}
@media screen and (max-width: 990px) {
 .activities-cards img {
  transform: scale(0.9);
 }
 .swiper-wrapper {
  padding: 0;
 }
}
@media screen and (max-width: 640px) {
 .activities-cards img {
  width: 100%;
  height: 380px;
  object-fit: cover;
 }
}

/* about */

#about-us {
 display: flex;
 justify-content: center;
 align-items: center;
 gap: 3rem;
 max-width: 95%;
 margin: 150px auto 0;
}
#about-us h2 {
 font-size: var(--title-font-size);
}
#about-us p {
 max-width: 556px;
 text-align: left;
 font-size: 1.6rem;
 margin-top: 3rem;
}
#about-us .section-button {
 width: 140px;
}
@media screen and (max-width: 992px) {
 #about-us {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 3rem;
  position: relative;
 }
 #about-us h2 {
  text-align: center;
 }
 #about-us p {
  max-width: 80%;
  margin: 0 auto;
  font-size: 1.7rem;
  margin-top: 3rem;
  color: black;
  font-weight: 500;
 }
 .about-img {
  position: absolute;
  right: 0;
  z-index: -1;
 }
 .about-img img {
  width: 100%;
  opacity: 0.3;
  border-radius: 12px;
 }

 /* .about-content {
  backdrop-filter: blur(20px);
  background: rgba(255, 255, 255, 0.301);
  height: 100%;
 } */
 .about-content .section-button {
  text-align: center;
  margin: 0 auto;
 }
}
/* about us page */
.sec-title {
 position: relative;
 z-index: 1;
 margin-bottom: 60px;
}

.sec-title .title {
 position: relative;
 display: block;
 font-size: 18px;
 line-height: 24px;
 color: var(--purple);
 font-weight: 500;
 margin-bottom: 15px;
}

.sec-title h2 {
 position: relative;
 display: block;
 font-size: 40px;
 line-height: 1.28em;
 color: #222222;
 font-weight: 600;
 padding-bottom: 18px;
}

.sec-title h2:before {
 position: absolute;
 content: "";
 left: 0px;
 bottom: 0px;
 width: 50px;
 height: 3px;
 background-color: #d1d2d6;
}

.sec-title .text {
 position: relative;
 font-size: 16px;
 line-height: 26px;
 color: #848484;
 font-weight: 400;
 margin-top: 35px;
}

.sec-title.light h2 {
 color: #ffffff;
}

.sec-title.text-center h2:before {
 left: 50%;
 margin-left: -25px;
}

.list-style-one li a:hover {
 color: var(--purple);
}

.btn-style-one {
 position: relative;
 display: inline-block;
 font-size: 17px;
 line-height: 30px;
 color: #ffffff;
 padding: 10px 30px;
 font-weight: 600;
 overflow: hidden;
 letter-spacing: 0.02em;
 background-color: #000;
 transition: all 0.3s ease;
}

.btn-style-one:hover {
 box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.291);
}
.about-section {
 position: relative;
 top: -100px;
 max-width: 95%;
 margin: 0 auto;
}
.about-section .row {
 display: flex;
 flex-wrap: wrap;
 /* gap: 1rem; */
}

.about-section .sec-title {
 margin-bottom: 45px;
}

.about-section .content-column {
 position: relative;
 margin-bottom: 50px;
 flex: 0 0 50%;
 max-width: 50%;
}

.about-section .content-column .inner-column {
 position: relative;
 /* padding-left: 30px; */
}

.about-section .text {
 margin-bottom: 20px;
 font-size: 16px;
 line-height: 26px;
 color: #848484;
 font-weight: 400;
}

.about-section .list-style-one {
 margin-bottom: 45px;
}

.about-section .btn-box {
 position: relative;
}

.about-section .btn-box a {
 padding: 15px 50px;
}

.about-section .image-column {
 position: relative;
 flex: 0 0 50%;
 max-width: 50%;
}

.about-section .image-column .text-layer {
 position: absolute;
 right: -110px;
 top: 50%;
 font-size: 325px;
 line-height: 1em;
 color: #ffffff;
 margin-top: -175px;
 font-weight: 500;
}

.about-section .image-column .inner-column {
 position: relative;
 /* padding-left: 80px; */
 padding-bottom: 0px;
}
.about-section .image-column .inner-column .author-desc {
 position: absolute;
 bottom: 16px;
 z-index: 1;
 background: var(--purple);
 padding: 10px 15px;
 left: 96px;
 width: calc(100% - 152px);
 border-radius: 50px;
}
.about-section .image-column .inner-column .author-desc h2 {
 font-size: 21px;
 letter-spacing: 1px;
 text-align: center;
 color: #fff;
 margin: 0;
}
.about-section .image-column .inner-column .author-desc span {
 font-size: 16px;
 letter-spacing: 6px;
 text-align: center;
 color: #fff;
 display: block;
 font-weight: 400;
}
.about-section .image-column .inner-column:before {
 content: "";
 position: absolute;
 width: calc(50% + 80px);
 height: calc(65% + 160px);
 top: -10px;
 left: 100px;
 background: transparent;
 z-index: 0;
 /* border: 30px solid var(--purple); */
}

.about-section .image-column .image-1 {
 position: relative;
}
.about-section .image-column .image-2 {
 position: absolute;
 left: 0;
 bottom: 0;
}

.about-section .image-column .image-2 img,
.about-section .image-column .image-1 img {
 box-shadow: 0 30px 50px rgba(8, 13, 62, 0.15);
 border-radius: 46px;
}

.about-section .image-column .video-link {
 position: absolute;
 left: 70px;
 top: 170px;
}

.about-section .image-column .video-link .link {
 position: relative;
 display: block;
 font-size: 22px;
 color: #191e34;
 font-weight: 400;
 text-align: center;
 height: 100px;
 width: 100px;
 line-height: 100px;
 background-color: #ffffff;
 border-radius: 50%;
 box-shadow: 0 30px 50px rgba(8, 13, 62, 0.15);
 -webkit-transition: all 300ms ease;
 -moz-transition: all 300ms ease;
 -ms-transition: all 300ms ease;
 -o-transition: all 300ms ease;
 transition: all 300ms ease;
}
@media screen and (max-width: 1150px) {
 .about-section .row {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
 }
 .about-section .content-column {
  flex: 0 0 100%;
  max-width: 100%;
  margin-top: 50px;
 }
 .about-section .image-column {
  flex: 0 0 100%;
  max-width: 100%;
 }
}
@media (min-width: 768px) {
 .content-column,
 .image-column {
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
 }
}
@media (min-width: 576px) {
 .content-column,
 .image-column {
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
 }
}
/* footer */
footer {
 position: relative;
 background: var(--footer-color);
 padding: 120px 20px;
 margin-top: 70px;
}

.footer-sections {
 display: grid;
 grid-template-columns: 2fr 1fr 1fr 1fr 1fr;
 /* align-items: center;  */
 justify-content: space-between;
}
.footer-sections ul {
 margin-top: 2rem;
}
.footer-section p {
 font-size: 1.3rem;
 width: 354px;
 line-height: 1.9;
 margin-block: 1rem 2rem;
 color: #5b5b5b;
}
.footer-section a {
 color: #5b5b5b;
 font-size: 1.3rem;
}
.footer-section h3 {
 font-size: 1.8rem;
}

.footer-social-icons {
 display: flex;
 gap: 3rem;
}
.footer-social-icons svg {
 fill: var(--purple);
 height: 50px;
 cursor: pointer;
}

.footer-section:last-child {
 /* margin-right: 4rem; */
 display: flex;
 flex-direction: column;
 align-items: flex-start;
}
.footer-section:last-child ul li {
 list-style: none;
 display: flex;
 align-items: center;
 gap: 1rem;
 /* line-height: 2.5rem; */
}
.footer-section:last-child ul li:nth-child(4) {
 align-items: flex-start;
}
.footer-section:last-child ul li :is(svg, a) {
 color: #5b5b5b;
 font-size: 1.3rem;
}
.footer-section:last-child ul li:hover :is(svg, a) {
 color: var(--purple);
 fill: var(--purple);
}
.footer-section:last-child ul li svg {
 width: 18px;
}
.phone-numbers {
 display: flex;
 flex-direction: column;
}

.footer-section:last-child img {
 margin-right: 1rem;
}
.footer-logo {
 width: 300px;
 height: auto;
}
.form-section {
 display: flex;
 justify-content: center;
 line-height: 1.2;
 background-color: #fff;
 align-items: center;
 font-size: 1.4rem;
 padding: 1rem 2rem;
 padding-right: 3rem;
 border-radius: 12px;
}
.footer-section:last-child button {
 outline: none;
 border: none;
 background-color: var(--purple);
 display: flex;
 justify-content: center;
 align-items: center;
 margin-left: 2rem;
 padding: 2rem;
 border-radius: 12px;
 cursor: pointer;
}
.footer-section:last-child button img {
 margin-right: 0;
}
#email::placeholder {
 color: #cfcfcf;
}

@media screen and (max-width: 1000px) {
 /* FOOTER */
 footer {
  padding-top: 10px;
 }
 .footer-sections {
  /* display: block; */
  padding: 0 2rem;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
 }
 .footer-section:first-child {
  grid-column: 1/3;
  text-align: center;
  /* align center */
 }
 .footer-section:first-child p {
  margin: 0 auto;
 }
 .footer-social-icons {
  margin-top: 20px;
  justify-content: center;
 }
 .footer-section {
  margin-bottom: 3rem;
 }
 .form-section {
  display: flex;
  /* flex-direction: column; */
  justify-content: center;
  line-height: 1.2;
  background-color: #fff;
  align-items: center;
  font-size: 1.4rem;
  padding: 1rem 2rem;
  padding-right: 1rem;
  border-radius: 12px;
 }
}
footer .copyright {
 position: absolute;
 width: 100%;
 bottom: 0;
 text-align: center;
}
.copyright a {
 color: var(--purple);
 margin-left: 2px;
}
.copyright a:hover {
 text-decoration: underline;
}
@media screen and (max-width: 530px) {
 footer {
  padding-bottom: 180px;
 }
 .footer-sections {
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  gap: 0;
 }
 .footer-section:first-child {
  grid-column: 1/2;
  text-align: center;
  /* align center */
 }
 .footer-section:not(:first-child) {
  max-height: 200px;
  margin-bottom: 0;
 }
}
/* scrollbar */
/* width */
::-webkit-scrollbar {
 width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
 background: #242323;
 /* display: none; */
}

/* Handle */
::-webkit-scrollbar-thumb {
 background: #888;
 border-radius: 100px;
 border: 0.5px solid#242323;
 width: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
 background: #555;
}

/* error page */
.error-container {
 text-align: center;
 margin: auto;
 padding: 4em;
}
.error-container img {
 width: 256px;
 height: 225px;
}
.error-container h1 {
 margin-top: 1rem;
 font-size: 35px;
 text-align: center;
}
.error-container h1 span {
 font-size: 60px;
 color: var(--purple);
}
.error-container div {
 margin-top: 20px;
}
.error-container a {
 color: var(--purple);
 background-color: #c0b4fd;
 font-size: 2rem;
 padding: 10px;
 border-radius: 5px;
 font-weight: 500;
}
/* book now box */
.booknow-container {
 background-color: #f2f2f2;
 padding: 10px 20px 15px;
 border-radius: 12px;
 max-width: 600px;
 margin: 0 auto;
}
.booknow-container h2 {
 font-size: 2rem;
 color: #000;
 text-align: center;
 margin-bottom: 10px;
}
.booknow-container form {
 display: grid;
 grid-template-columns: 1fr 1fr;
 gap: 15px;
}
.booknow-container .form_group {
 display: flex;
 flex-direction: column;
 justify-content: flex-start;
 align-items: center;
}
.booknow-container .form_group label {
 font-size: 1.5rem;
 line-height: 1.2;
 color: #000;
 font-weight: 500;
}
.booknow-container .form_group input {
 outline: none;
 border: none;
 border-radius: 5px;
 padding: 10px;
 font-size: 1.5rem;
 margin-top: 10px;
 width: 100%;
}
.booknow-container .form_group input::placeholder {
 color: #cfcfcf;
}
.booknow-container .form_group_submit {
 text-align: center;
 grid-column: 1/3;
}
.booknow-container .form_group_submit button {
 /* background-color: var(--purple); */
 background-color: #000;
 color: #fff;
 font-weight: 500;
 cursor: pointer;
 text-align: center;
 width: 100%;
 border: none;
 outline: none;
 padding: 10px;
 border-radius: 5px;
 min-height: 50px;
 font-size: 15px;
}
.booknow-container .form_group_submit button div {
 height: 30px;
 width: 30px;
}
.booknow-container .form_group_submit button div::before,
.booknow-container .form_group_submit button div::after {
 border-width: 3px;
}
.poster {
 width: 100%;
 max-width: 500px;
 position: sticky;
 top: 15px;
 margin-top: 15px;
 margin-left: auto;
 margin-right: auto;
}
.poster img {
 width: 100%;
}
