@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

* {
 margin: 0;
 padding: 0;
 box-sizing: border-box;
}

:root {
 --light: #eff4ee;
 --yellow: #f7c720;
 --red: #f94b0e;
 --blue: #389fd1;
 --purple: #540ac9;
 --light-blue: #42b4f4;
 --teal: #4484a4;
}

html {
 scroll-behavior: smooth;
 font-size: 10px;
}

body {
 font-family: "Poppins", sans-serif;
 line-height: 3.2rem;
 overflow-x: hidden;
}

ul,
li {
 list-style: none;
}
a {
 text-decoration: none;
 color: white;
}

img {
 object-fit: cover;
}

.App {
 min-height: 100vh;
}
.lazy-load-image-background.blur {
 border-radius: 10px;
}
.image-preview {
 display: none;
 width: 100%;
 height: 100vh;
 position: absolute;
 top: 0;
 left: 0;
 z-index: 10;
}
.image-preview.open {
 display: block;
}
