.packages {
 position: relative;
 top: -100px;
}
/* single package page */
.package-container {
 position: relative;
 top: -100px;
 max-width: 95%;
 margin: 0 auto 60px;
 padding: 0 10px;
 display: grid;
 grid-template-columns: 2fr 1fr;
 grid-gap: 20px;
}
@media screen and (max-width: 1200px) {
 .package-container {
  grid-template-columns: 1fr;
 }
}

.package-name {
 font-size: 2rem;
 margin-bottom: 20px;
 display: flex;
 justify-content: space-between;
}

.package-name p {
 text-align: left;
 color: rgb(133, 133, 133);
}
.package-category {
 margin: 5px 0;
 font-size: 17px;
}
.pricing,
.pricing p {
 text-align: right;
}
.duration {
 display: flex;
}
.duration p {
 margin-right: 10px;
 font-size: 12px;
 background-color: #000;
 color: white;
 padding: 5px 12px;
 border-radius: 30px;
 border: 2px solid #000;
 line-height: 1.5;
}
.duration p:not(:first-child) {
 /* margin-right: 0; */
 background-color: #fff;
 color: #000;
}
.pricing p {
 font-size: 1.5rem;
 color: rgb(133, 133, 133);
}
@media screen and (max-width: 990px) {
 .package-name {
  font-size: 1.5rem;
 }
 .pricing p {
  font-size: 1.2rem;
 }
}
.pricing .originalPrice {
 text-decoration: line-through;
}

.package-description {
 margin: 20px 0;
}
.package-description h2 {
 font-size: 2.25rem;
}
.package-description p {
 position: relative;
 font-size: 1.5rem;
 line-height: 1.5;
 color: rgb(133, 133, 133);
 /* padding-left: 2rem; */
 margin-top: 10px;
}
.package-description svg {
 height: 15px;
 width: 15px;
 position: absolute;
 left: 0;
 top: 3px;
}
.package-description > div {
 margin-bottom: 15px;
}
.package-description .offer p {
 margin-bottom: 10px;
}
.vertical-timeline-element-title {
 font-size: 1.75rem;
}
.vertical-timeline-element p {
 font-size: 1.5rem !important;
 opacity: 0.8 !important;
}
.vertical-timeline-element-content {
 padding-left: 2em !important;
 box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.25) !important;
 border-radius: 10px !important;
}
.tags {
 display: flex;
 flex-wrap: wrap;
 gap: 5px;
 margin-top: 10px;
}
.tags .tag {
 font-size: 1.2rem;
 padding: 0 8px;
 border-radius: 30px;
 border: 2px solid currentColor;
 text-transform: capitalize;
}
@media screen and (max-width: 767px) {
 .vertical-timeline-element-title {
  font-size: 1.5rem;
  line-height: 1.5;
 }
 .vertical-timeline-element p {
  font-size: 1.2rem !important;
 }
}
