.overlay {
 position: fixed;
 z-index: 99;
 height: 100vh;
 width: 100%;
 background-color: white;
}
.overlay .loader {
 display: flex;
 justify-content: center;
 align-items: center;
 height: 100%;
}

.modal-overlay .modal__container .modal-body {
 position: absolute;
 top: 45%;
 left: 50%;
 transform: translate(-50%, -50%);
 width: 90%;
}
.modal-overlay .modal__container .modal-body center {
 margin: 20px;
}
.modal-overlay .modal__container .modal-body center strong {
 font-size: 25px;
 position: relative;
 top: -30px;
}

.modal-overlay .modal__container .modal-body center p {
 position: relative;
 top: -10px;
 font-size: 14px;
 line-height: 1.5;
}

.modal-overlay .modal__container .modal-buttons {
 position: absolute;
 bottom: 30px;
 right: 10px;
}
.btn {
 border: none;
 padding: 10px 15px;
 font-size: 16px;
 font-weight: 600;
 border-radius: 10px;
 width: 145px;
 height: 45px;
 box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.3);
 margin: 0 10px;
 color: rgba(0, 0, 0, 0.5);
 cursor: pointer;
}
.modal-overlay .modal__container .modal-buttons button.btn-purple {
 background: var(--purple);
 color: white;
}
