.contact_us_6 * {
 font-family: Nunito, sans-serif;
}

.contact_us_6 .text-blk {
 margin-bottom: 0px;

 line-height: 25px;
}

.contact_us_6 .responsive-cell-block {
 min-height: 75px;
}

.contact_us_6 input:focus,
.contact_us_6 textarea:focus {
 outline-color: initial;
 outline-style: none;
 outline-width: initial;
}

.contact_us_6 .container-block {
 min-height: 75px;
 width: 100%;
 padding-top: 10px;
 padding-right: 10px;
 padding-bottom: 10px;
 padding-left: 10px;
 display: block;
}

.contact_us_6 .responsive-container-block {
 min-height: 75px;
 display: flex;
 flex-wrap: wrap;
 justify-content: flex-start;
 margin-right: auto;
 margin-bottom: 0px;
 margin-left: auto;
}
.contact_us_6 .responsive-container-block.container {
 position: relative;
 top: -100px;
}

.contact_us_6 .responsive-container-block.big-container {
 padding-top: 10px;
 padding-right: 30px;
 width: 35%;
 padding-bottom: 10px;
 padding-left: 30px;
 background-color: #03a9f4;
 position: absolute;
 height: 950px;
 right: 0px;
}

.contact_us_6 .responsive-container-block.container {
 position: relative;
 min-height: 75px;
 flex-direction: row;
 z-index: 2;
 flex-wrap: nowrap;
 align-items: center;
 justify-content: center;
 padding: 0 30px;
 max-width: 1320px;
 margin-right: auto;
 margin-left: auto;
}

.contact_us_6 .container-block.form-wrapper {
 background-color: white;
 max-width: 450px;
 text-align: center;
 padding: 50px 40px;
 box-shadow: rgba(0, 0, 0, 0.05) 0px 4px 20px 7px;
 border-radius: 10px;
 margin: 20 0 60px;
}

.contact_us_6 .text-blk.contactus-head {
 font-size: 36px;
 line-height: 52px;
 font-weight: 900;
}

.contact_us_6 .text-blk.contactus-subhead {
 color: #9c9c9c;
 width: 300px;
 margin-right: auto;
 margin-bottom: 50px;
 margin-left: auto;
 display: none;
}

.contact_us_6 .responsive-cell-block.wk-desk-6.wk-ipadp-6.wk-tab-12.wk-mobile-12 {
 margin-bottom: 26px;

 min-height: 50px;
}

.contact_us_6 .input {
 width: 100%;
 height: 50px;
 padding-top: 1px;
 padding-right: 15px;
 padding-bottom: 1px;
 padding-left: 15px;
 border: 2px solid #eeeeee;
 font-size: 16px;
 color: black;
}

.contact_us_6 .textinput {
 width: 98%;
 min-height: 150px;
 padding-top: 20px;
 padding-right: 15px;
 padding-bottom: 20px;
 padding-left: 15px;
 border: 2px solid #eeeeee;
 font-size: 16px;
}

.contact_us_6 .submit-btn {
 width: 98%;
 background-color: #000;
 height: 60px;
 font-size: 20px;
 font-weight: 700;
 color: white;
 border-radius: 40px;
 border: none;
 cursor: pointer;
 text-align: center;
}

.contact_us_6 .form-box {
 z-index: 2;

 margin-right: 48px;
 margin-bottom: 0px;
}
.btn-loader {
 width: 40px;
 height: 40px;
 border-radius: 50%;
 position: relative;
 margin: 0 auto;
 animation: rotate 1s linear infinite;
}
.btn-loader::before,
.btn-loader::after {
 content: "";
 box-sizing: border-box;
 position: absolute;
 inset: 0px;
 border-radius: 50%;
 border: 5px solid #fff;
 animation: prixClipFix 2s linear infinite;
}
.btn-loader::after {
 inset: 8px;
 transform: rotate3d(90, 90, 0, 180deg);
 border-color: var(--yellow);
}

@keyframes rotate {
 0% {
  transform: rotate(0deg);
 }
 100% {
  transform: rotate(360deg);
 }
}

@keyframes prixClipFix {
 0% {
  clip-path: polygon(50% 50%, 0 0, 0 0, 0 0, 0 0, 0 0);
 }
 50% {
  clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 0, 100% 0, 100% 0);
 }
 75%,
 100% {
  clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 100% 100%, 100% 100%);
 }
}

.contact_us_6 .text-blk.input-title {
 text-align: left;
 padding-top: 0px;
 padding-right: 0px;
 padding-bottom: 0px;
 padding-left: 10px;
 font-size: 14px;
 margin-bottom: 5px;
 color: #9c9c9c;
}

.contact_us_6 ::placeholder {
 color: #dadada;
}

.contact_us_6 .mob-text {
 display: block;
 text-align: left;
 margin-bottom: 25px;
}

.contact_us_6 .responsive-cell-block.wk-tab-12.wk-mobile-12.wk-desk-12.wk-ipadp-12 {
 margin-bottom: 20px;
}

.contact_us_6 .text-blk.contactus-subhead.color {
 color: white;
}
.contact-section ul li :is(a, svg) {
 color: #000;
 fill: #000;
 font-size: 15px;
}
.contact-section ul li:hover :is(a, svg) {
 color: var(--purple);
 fill: var(--purple);
}
.contact-section ul li svg {
 height: 18px;
}
.contact-section ul li {
 display: flex;
 gap: 5px;
 align-items: center;
 margin-bottom: 10px;
}
.contact-section ul .phone-numbers {
 flex-direction: row;
 gap: 5px;
 flex-wrap: wrap;
}
.contact_us_6 .map-box iframe {
 max-width: 800px;
 max-height: 520px;
 width: 100%;
 height: 520px;
 /* background-color: #d9d9d9;
 background-image: url("../images/map.png");
 background-size: cover;
 background-position-x: 50%;
 background-position-y: 50%; */
}

.contact_us_6 .map-part {
 width: 100%;
 height: 100%;
}

.contact_us_6 .text-blk.map-contactus-head {
 font-weight: 900;
 font-size: 22px;
 line-height: 32px;
 margin-bottom: 10px;
 color: var(--purple);
}

.contact_us_6 .text-blk.map-contactus-subhead {
 max-width: 400px;
 margin-bottom: 20px;
 font-size: 14px;
}

.contact_us_6 .social-media-links.mob {
 margin-bottom: 30px;
 width: 230px;
 display: flex;
 justify-content: flex-start;
}

.contact_us_6 .link-img {
 width: 30px;
 height: 30px;
 margin-right: 25px;
 margin-bottom: 0px;
}

.contact_us_6 .link-img.image-block {
 margin-bottom: 0px;
}

.contact_us_6 .social-icon-link {
 margin: 0 25px 0 0;
 padding: 0 0 0 0;
}
.contact_us_6 .social-icon-link svg {
 fill: var(--purple);
 height: 35px;
}

@media (max-width: 1024px) {
 .contact_us_6 .responsive-container-block.container {
  justify-content: center;
 }

 .contact_us_6 .map-box {
  position: absolute;
  top: 0px;
  max-height: 320px;
 }

 .contact_us_6 .map-box {
  max-width: 100%;
  width: 100%;
 }

 .contact_us_6 .responsive-container-block.container {
  padding-top: 0px;
  padding-right: 0px;
  padding-bottom: 0px;
  padding-left: 0px;
  top: -160px;
 }

 .contact_us_6 .map-part {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
 }

 .contact_us_6 .container-block.form-wrapper {
  margin-bottom: 0px;
 }

 .contact_us_6 .mob-text {
  display: block;
 }

 .contact_us_6 .form-box {
  margin-top: 200px;
  margin-right: 60px;
  margin-bottom: 40px;
 }

 .contact_us_6 .link-img {
  margin-bottom: 0px;

  display: flex;
  justify-content: space-evenly;
 }

 .contact_us_6 .social-media-links.mob {
  justify-content: space-evenly;
 }

 .contact_us_6 .responsive-cell-block.wk-desk-7.wk-ipadp-12.wk-tab-12.wk-mobile-12 {
  text-align: center;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: row;
 }

 .contact_us_6 .text-blk.contactus-subhead {
  display: block;
 }

 .contact_us_6 .mob-text {
  text-align: center;

  margin-bottom: 0px;
 }

 .contact_us_6 .responsive-container-block.container {
  flex-wrap: wrap;
 }

 .contact_us_6 .form-box {
  margin-top: 200px;

  margin-bottom: 40px;
 }
}

@media (max-width: 768px) {
 .contact_us_6 .submit-btn {
  width: 100%;
 }

 .contact_us_6 .input {
  width: 100%;
 }

 .contact_us_6 .textinput {
  width: 100%;
 }

 .contact_us_6 .container-block.form-wrapper {
  margin-top: 80px;

  margin-bottom: 0px;
 }

 .contact_us_6 .text-blk.input-title {
  padding-top: 0px;
  padding-right: 0px;
  padding-bottom: 0px;
  padding-left: 0px;
 }

 .contact_us_6 .form-box {
  padding-top: 0px;
  padding-right: 20px;
  padding-bottom: 0px;
  padding-left: 20px;
 }

 .contact_us_6 .container-block.form-wrapper {
  padding-top: 50px;
  padding-right: 15px;
  padding-bottom: 50px;
  padding-left: 15px;
 }

 .contact_us_6 .mob-text {
  display: block;
 }

 .contact_us_6 .responsive-container-block.container {
  padding-top: 0px;
  padding-right: 0px;
  padding-bottom: 0px;
  padding-left: 0px;
 }

 .contact_us_6 .form-box {
  margin-top: 200px;

  margin-bottom: 0px;
 }

 .contact_us_6 .container-block.form-wrapper {
  margin-bottom: 0px;
 }

 .contact_us_6 .form-box {
  margin-top: 220px;

  margin-bottom: 0px;
 }

 .contact_us_6 .form-box {
  margin-top: 220px;

  margin-bottom: 50px;
 }

 .contact_us_6 .text-blk.contactus-head {
  font-size: 32px;
  line-height: 40px;
 }
}

@media (max-width: 500px) {
 .contact_us_6 .container-block.form-wrapper {
  padding-top: 50px;
  padding-right: 15px;
  padding-bottom: 50px;
  padding-left: 15px;
 }

 .contact_us_6 .container-block.form-wrapper {
  margin-top: 60px;

  margin-bottom: 0px;
 }

 .contact_us_6 .responsive-cell-block.wk-ipadp-6.wk-tab-12.wk-mobile-12.wk-desk-6 {
  margin-bottom: 15px;
 }

 .contact_us_6 .responsive-container-block {
  margin-bottom: 35px;
 }

 .contact_us_6 .text-blk.input-title {
  font-size: 12px;
 }

 .contact_us_6 .text-blk.contactus-head {
  font-size: 26px;
  line-height: 35px;
 }

 .contact_us_6 .input {
  height: 45px;
 }
}
.wk-desk-1 {
 width: 8.333333%;
}

.wk-desk-2 {
 width: 16.666667%;
}

.wk-desk-3 {
 width: 25%;
}

.wk-desk-4 {
 width: 33.333333%;
}

.wk-desk-5 {
 width: 41.666667%;
}

.wk-desk-6 {
 width: 50%;
}

.wk-desk-7 {
 width: 58.333333%;
}

.wk-desk-8 {
 width: 66.666667%;
}

.wk-desk-9 {
 width: 75%;
}

.wk-desk-10 {
 width: 83.333333%;
}

.wk-desk-11 {
 width: 91.666667%;
}

.wk-desk-12 {
 width: 100%;
}

@media (max-width: 1024px) {
 .wk-ipadp-1 {
  width: 8.333333%;
 }

 .wk-ipadp-2 {
  width: 16.666667%;
 }

 .wk-ipadp-3 {
  width: 25%;
 }

 .wk-ipadp-4 {
  width: 33.333333%;
 }

 .wk-ipadp-5 {
  width: 41.666667%;
 }

 .wk-ipadp-6 {
  width: 50%;
 }

 .wk-ipadp-7 {
  width: 58.333333%;
 }

 .wk-ipadp-8 {
  width: 66.666667%;
 }

 .wk-ipadp-9 {
  width: 75%;
 }

 .wk-ipadp-10 {
  width: 83.333333%;
 }

 .wk-ipadp-11 {
  width: 91.666667%;
 }

 .wk-ipadp-12 {
  width: 100%;
 }
}

@media (max-width: 768px) {
 .wk-tab-1 {
  width: 8.333333%;
 }

 .wk-tab-2 {
  width: 16.666667%;
 }

 .wk-tab-3 {
  width: 25%;
 }

 .wk-tab-4 {
  width: 33.333333%;
 }

 .wk-tab-5 {
  width: 41.666667%;
 }

 .wk-tab-6 {
  width: 50%;
 }

 .wk-tab-7 {
  width: 58.333333%;
 }

 .wk-tab-8 {
  width: 66.666667%;
 }

 .wk-tab-9 {
  width: 75%;
 }

 .wk-tab-10 {
  width: 83.333333%;
 }

 .wk-tab-11 {
  width: 91.666667%;
 }

 .wk-tab-12 {
  width: 100%;
 }
}

@media (max-width: 500px) {
 .wk-mobile-1 {
  width: 8.333333%;
 }

 .wk-mobile-2 {
  width: 16.666667%;
 }

 .wk-mobile-3 {
  width: 25%;
 }

 .wk-mobile-4 {
  width: 33.333333%;
 }

 .wk-mobile-5 {
  width: 41.666667%;
 }

 .wk-mobile-6 {
  width: 50%;
 }

 .wk-mobile-7 {
  width: 58.333333%;
 }

 .wk-mobile-8 {
  width: 66.666667%;
 }

 .wk-mobile-9 {
  width: 75%;
 }

 .wk-mobile-10 {
  width: 83.333333%;
 }

 .wk-mobile-11 {
  width: 91.666667%;
 }

 .wk-mobile-12 {
  width: 100%;
 }
}
