.hotels {
 position: relative;
 top: -100px;
}
.page-heading {
 max-width: 90%;
 margin: 0 auto;
 margin-bottom: 20px;
}
.packages .page-heading {
 max-width: 95%;
}
.page-heading h2 {
 font-size: 3.5rem;
}
.hotel-image span {
 width: 100%;
}
.page-heading p {
 font-size: 1.5rem;
 color: rgb(133, 133, 133);
}

/* single hotel page */
.hotel-container {
 position: relative;
 top: -100px;
 max-width: 95%;
 margin: 0 auto 60px;
 padding: 0 10px;
 display: grid;
 grid-template-columns: 2fr 1fr;
 grid-gap: 20px;
}
@media screen and (min-width: 1600px) {
 .hotel-container {
  max-width: 80%;
 }
}
@media screen and (min-width: 1850px) {
 .hotel-container {
  max-width: 75%;
 }
}
@media screen and (max-width: 1200px) {
 .hotel-container {
  grid-template-columns: 1fr;
 }
}

.hotel-name {
 font-size: 2rem;
 margin-bottom: 20px;
 display: flex;
 justify-content: space-between;
}

.hotel-name p {
 text-align: left;
 color: rgb(133, 133, 133);
}
.pricing,
.pricing p {
 text-align: right;
}
.pricing p {
 font-size: 1.5rem;
 color: rgb(133, 133, 133);
}
@media screen and (max-width: 990px) {
 .hotel-name {
  font-size: 1.5rem;
 }
 .pricing p {
  font-size: 1.2rem;
 }
}
.pricing .originalPrice {
 text-decoration: line-through;
}
.main-image {
 position: relative;
 max-width: max(100%, 1000px);
 height: auto;
 max-height: 600px;
 object-fit: cover;
 overflow: hidden;
 margin-bottom: 20px;
}
.main-image span {
 width: 100%;
}
.main-image .arrows {
 position: absolute;
 top: 50%;
 transform: translateY(-50%);
 width: 100%;
 display: flex;
 justify-content: space-between;
 z-index: 20;
}
.main-image .arrows svg {
 color: white;
 cursor: pointer;
 filter: drop-shadow(0 0 5px rgba(0, 0, 0, 0.5));
}
.main-image img {
 width: 100%;
 max-width: max(100%, 1000px);
 height: 100%;
 max-height: 600px;
 border-radius: 2px;
}
.sub-images {
 display: flex;
 gap: 20px;
}
.sub-image {
 max-width: 100px;
 height: 75px;
}
.sub-image img {
 width: 100%;
 height: 75px;
 object-fit: cover;
 border-radius: 2px;
}
.sub-image span {
 height: 75px;
}
@media screen and (max-width: 767px) {
 .sub-images {
  gap: 10px;
 }
}
.sub-images img {
 width: 100%;
 height: 100%;
}
.sub-images img:hover {
 cursor: pointer;
}
.sub-images img.active {
 border: 3px solid var(--purple);
}
.hotel-description {
 margin-top: 20px;
}
.hotel-description h2 {
 font-size: 2.25rem;
}
.hotel-description p {
 position: relative;
 font-size: 1.5rem;
 line-height: 1.5;
 color: rgb(133, 133, 133);
 /* padding-left: 2rem; */
 margin-top: 10px;
 display: flex;
 align-items: center;
 gap: 15px;
}
.hotel-description svg {
 height: 20px;
 width: 20px;
 /* position: absolute;
 left: 0;
 top: 3px; */
}
.hotel-description > div {
 margin-bottom: 15px;
}
.hotel-description .offer p {
 margin-bottom: 10px;
}
